@import 'styles/colors';
@import 'tools/styles/helpers';

.primaryButton {
    @include defaultButton();
    @include borderRadius(12px);

    background: $purple;

    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: $white;
    cursor: pointer;
}

.isDisabled {
    cursor: not-allowed;
    opacity: 0.33;
}
