@import 'tools/styles/flex';

.loading {
    @include dFlex();
    @include flexColumn();
    @include justifyContentCenter();
    @include alignItemsCenter();

    width: 100%;
}

.isPage {
    @include dFlex();
    @include flexColumn();
    @include justifyContentStart();
    @include alignItemsCenter();

    height: 60vh;
}
