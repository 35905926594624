@import 'tools/styles/flex';

.paymentMethod {
    @include dFlex();
    @include flexRow();
    @include justifyContentCenter();
    @include alignItemsCenter();

    width: auto;
    height: 67px;

    cursor: pointer;

    img {
        width: auto;
        height: 100%;
    }
}
