@import 'tools/styles/flex';

.notFound {
    @include dFlex();
    @include flexColumn();
    @include alignItemsCenter();

    .dinoImage {
        width: 144px;
        height: 144px;

        margin-bottom: 50px;
    }
    .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;

        margin-bottom: 8px;
    }
    .subtitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
    }
}
