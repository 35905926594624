@import 'styles/colors';
@import 'tools/styles/flex';

.header {
    @include dFlex();
    @include flexRow();
    @include alignItemsCenter();

    width: 100%;
    height: 65px;

    background: $purple;

    padding-left: 16px;
}
