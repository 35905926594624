@import 'styles/colors';
@import 'tools/styles/flex';
@import 'tools/styles/helpers';

.card {
    .header {
        @include dFlex();
        @include flexRow();
        @include alignItemsCenter();
        @include borderRadius(16px);

        background: $lightGrey;

        gap: 8px;
        padding: 4px;
        margin-bottom: 16px;

        .order {
            @include dFlex();
            @include flexRow();
            @include justifyContentCenter();
            @include alignItemsCenter();
            @include borderRadius(50%);

            width: 24px;
            height: 24px;

            background: $white;
        }
        .label {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
        }
    }
    .content {

    }
}
