@import 'styles/z';
@import 'styles/colors';
@import 'tools/styles/flex';
@import 'tools/styles/helpers';
@import 'tools/styles/animations';

.attachmentInput {
    .content {
        @include borderRadius(12px);

        position: relative;

        width: 100%;
        height: 58px;

        border: 1px solid $superLightGrey;

        cursor: pointer;

        .label {
            @include easeTransition();

            position: absolute;

            top: 21px;
            left: 16px;
            z-index: $inputLabelZ;

            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            color: $blue;
        }
        .clipImage {
            position: absolute;

            top: 21px;
            right: 16px;
            z-index: $inputLabelZ;

            width: 22px;
            height: 22px;
        }
    }
    .attachment {
        width: 100%;
    }
}

.isError {
    .content {
        .label {
            color: $red;
        }
    }
}