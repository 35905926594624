@import 'styles/colors';
@import 'tools/styles/flex';
@import 'tools/styles/helpers';

.backButton {
    @include dFlex();
    @include flexRow();
    @include alignItemsCenter();
    @include defaultButton();
    @include borderRadius(12px);
    @include removeLinkUnderline();

    background: $lightPurple;

    font-size: 21px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.06em;
    color: $white;

    gap: 10px;
    padding: 8px 8px 8px 16px;

    .image {
        width: 24px;
        height: 24px;
    }
}
