
@import 'styles/colors';
@import 'tools/styles/flex';
@import 'tools/styles/grid';
@import 'tools/styles/helpers';

.paymentMethods {
    .header {
        .headerContent {
            @include dFlex();
            @include flexRow();
            @include alignItemsCenter();
            @include borderRadius(12px);

            width: 286px;
            height: 40px;

            background: $lightPurple;

            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            color: $white;

            padding-left: 7px;

            .arrowImage {
                width: 24px;
                height: 24px;

                margin-right: 10px;
            }
        }
    }
    .table {
        @include dGrid();
        @include gridColumns(1fr 1fr 1fr);

        gap: 23px;

        padding: 53px 32px 0 32px;

        .paymentMethod {

        }
    }
}
