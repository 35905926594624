@import 'styles/z';
@import 'styles/colors';
@import 'tools/styles/helpers';
@import 'tools/styles/animations';

.stringInput {
    .content {
        @include borderRadius(12px);

        position: relative;

        width: 100%;
        height: 58px;

        border: 1px solid $superLightGrey;

        .label {
            @include easeTransition();

            position: absolute;

            top: 21px;
            left: 16px;
            z-index: $inputLabelZ;

            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            color: $blue;
        }
        .input {
            @include defaultInput();

            position: absolute;

            bottom: 12px;
            left: 16px;

            opacity: 0;

            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
        }
    }
}

.isValuedFocused {
    .content {
        .label {
            top: 12px;

            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            color: $lightLightGray;
        }
        .input {
            opacity: 1;
        }
    }
}

.isError {
    .content {
        .label {
            color: $red;
        }
    }
}
