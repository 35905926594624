@import 'styles/colors';
@import 'tools/styles/helpers';

.baseButton {
    @include defaultButton();

    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
    color: $blue;
}

.isDisabled {
    cursor: not-allowed;
}
