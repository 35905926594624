.payment {
    .header {

    }
    .content {
        padding: 53px 32px 9px 32px;

        .footer {
            margin-top: 127px;
        }
    }
}
