@import 'styles/colors';
@import 'tools/styles/flex';

.footer {
    @include dFlex();
    @include flexRow();
    @include justifyContentCenter();
    @include alignItemsCenter();

    gap: 1px;

    .shield {
        width: 24px;
        height: 24px;
    }
    .text {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: $gray;
    }
}
