@import 'tools/styles/flex';

.paymentForm {
    .amountCard {
        margin-bottom: 23px;
    }
    .detailFieldsCard {
        margin-bottom: 23px;

        .subtitle {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;

            padding-left: 10px;
            margin-bottom: 15px;
        }
        .detailFieldsList {
            @include dFlex();
            @include flexColumn();

            gap: 15px;
        }
    }
    .clientFieldsCard {
        margin-bottom: 20px;

        .subtitle {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;

            padding-left: 10px;
            margin-bottom: 15px;
        }
        .clientFieldsList {
            @include dFlex();
            @include flexColumn();

            gap: 15px;
        }
    }
    .attachmentInput {
        margin-bottom: 20px;
    }
    .smsCodeInput {
        margin-bottom: 20px;
    }
    .submitButton {
        width: 100%;
        height: 44px;

        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
    }
}
