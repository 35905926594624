@mixin dFlex {
    display: -ms-flexbox;
    display: flex;
}
@mixin flexRow {
    -ms-flex-direction: row;
    flex-direction: row;
}
@mixin flexRowReverse() {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}
@mixin flexColumn {
    -ms-flex-direction: column;
    flex-direction: column;
}
@mixin flexColumnReverse() {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}
@mixin justifyContentStart {
    -ms-flex-pack: start;
    justify-content: flex-start;
}
@mixin justifyContentEnd {
    -ms-flex-pack: end;
    justify-content: flex-end;
}
@mixin justifyContentCenter {
    -ms-flex-pack: center;
    justify-content: center;
}
@mixin justifyContentBetween {
    -ms-flex-pack: justify;
    justify-content: space-between;
}
@mixin justifyContentAround {
    -ms-flex-pack: justify;
    justify-content: space-around;
}
@mixin alignItemsStart {
    -ms-flex-align: start;
    align-items: flex-start;
}
@mixin alignItemsEnd {
    -ms-flex-align: end;
    align-items: flex-end;
}
@mixin alignItemsCenter {
    -ms-flex-align: center;
    align-items: center;
}
@mixin alignSelfStart() {
    -webkit-align-self: flex-start;
    align-self: flex-start;
}
@mixin flexWrap() {
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
@mixin flexNoWrap() {
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
@mixin flexGrow($value) {
    -ms-flex-grow: $value;
    -webkit-flex-grow: $value;
    flex-grow: $value;
}
@mixin flexShrink($value) {
    -ms-flex-shrink: $value;
    -webkit-flex-shrink: $value;
    flex-shrink: $value;
}
@mixin flexBasis($value) {
    -ms-flex-basis: $value;
    -webkit-flex-basis: $value;
    flex-basis: $value;
}
