@import 'styles/colors';

* {
    box-sizing: border-box;
}

body {
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased !important;
    -webkit-text-size-adjust: 100%;

    margin: 0;
}

input, textarea, button {
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased !important;
}

@font-face { font-family: "Inter"; font-style: normal; font-weight: 400; font-display: swap; src: url("./styles/font/Inter-Regular.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 500; font-display: swap; src: url("./styles/font/Inter-Medium.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 600; font-display: swap; src: url("./styles/font/Inter-SemiBold.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 700; font-display: swap; src: url("./styles/font/Inter-Bold.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 800; font-display: swap; src: url("./styles/font/Inter-ExtraBold.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 900; font-display: swap; src: url("./styles/font/Inter-Black.woff2") format("woff2"); }
