@mixin row() {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
@mixin col1() {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}
@mixin col2() {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
@mixin col3() {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}
@mixin col4() {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
@mixin col5() {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
@mixin col6() {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
@mixin col7() {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
@mixin col8() {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
@mixin col9() {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}
@mixin col10() {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}
@mixin col11() {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}
@mixin col12() {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
@mixin dGrid() {
    display: -ms-grid;
    display: grid;
}
@mixin gridColumns($value) {
    -ms-grid-columns: $value;
    grid-template-columns: $value;
}
@mixin gridRows($value) {
    -ms-grid-rows: $value;
    grid-template-rows: $value;
}
