$white: #FFFFFF;
$black: #000000;

$blue: #1667C1;
$purple: #635BFF;
$lightPurple: #736BFF;

$red: #F20000;
$green: #4FA845;

$gray: #777777;
$lightGrey: #094B951C;
$lightLightGray: #898989;
$superLightGrey: #E6E6E6;
