@import 'styles/colors';
@import 'tools/styles/flex';
@import 'tools/styles/helpers';

.fieldDisplay {
    @include dFlex();
    @include flexRow();
    @include justifyContentBetween();
    @include alignItemsCenter();
    @include borderRadius(12px);

    border: 1px solid $superLightGrey;

    padding: 12px 16px;

    .content {
        .label {
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            color: $lightLightGray;

            margin-bottom: 4px;
        }
        .value {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
        }
    }
    .copyButton {

    }
    .copied {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: $green;
        cursor: pointer;

        img {
            width: 13px;
            height: 9px;

            margin-right: 7px;
        }
    }
}
