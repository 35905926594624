@import 'tools/styles/flex';

.success {
    @include dFlex();
    @include flexColumn();
    @include alignItemsCenter();

    position: relative;

    .successImage {
        width: 290px;
        height: 157px;

        margin-bottom: 73px;
        margin-right: -20px;
    }
    .checkImage {
        position: absolute;

        top: 100px;
        left: calc(50vw - 74px);

        width: 74px;
        height: 74px;
    }
    .text {
        .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;

            margin-bottom: 10px;
        }
        .subtitle {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            text-align: center;
        }
    }
}
